import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const DataPolicy = () => (
  <Layout>
    <SEO title="EasyBusy: Data Collection and Deletion, GDPR" />
    <div className={'container'}>
      <div className={'content policies'}>
        <div className={'title'}>
          <h1>Data Collection and Deletion, GDPR</h1>
        </div>

        <h2 className="c4" id="h.2ozyymlk2rw">
          <span className="c1">Consent</span>
        </h2>
        <p className="c2">
          <span>W</span>
          <span className="c0">
            e show a consent message during signup that explains our data collection
            practices. Therefore, our lawful basis for collecting personal data is
            consent.
          </span>
        </p>
        <p className="c2">
          <span className="c0">
            We use cookies and Browser Local Storage to securely identify your
            account and keep you signed in.
          </span>
        </p>
        <p className="c2">
          <span className="c0">
            A customer may withdraw their consent and delete their account at any
            time by manually removing content, and by following the instructions
            below.
          </span>
        </p>
        <h2 className="c2 c6" id="h.ma7g4ggo0tdb">
          <span className="c1">Data removal request</span>
        </h2>
        <p className="c2">
          <span className="c0">
            If at any time you wish to remove all your personal data, and the User
            Content you created on our apps and services, please contact us via email
            at support@easybusydo.com. We may request you to provide proof of
            ownership of your account, such as sending the email from the email
            account associated with your EasyBusy account.
          </span>
        </p>
        <p className="c2">
          <span className="c0">
            After receiving such a request, we will permanently remove all your
            personal data and user content on our storage, and with some of our
            partners that allow us to do so, within 5 business days.{' '}
          </span>
        </p>
        <h2 className="c4" id="h.yjtig8vh1xgd">
          <span className="c1">Contact Us</span>
        </h2>
        <p className="c2">
          <span className="c0">
            You may contact us regarding data deletion via email to
            support@easybusydo.com
          </span>
        </p>
        <p className="c2">
          <span className="c0">Last updated: April 12, 2021</span>
        </p>
        <p className="c7">
          <span className="c0" />
        </p>
      </div>
    </div>
  </Layout>
);

export default DataPolicy;
